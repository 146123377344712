/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';


.ecommerce button.collapsed::after {
    content: "\f0415";
    top: 50%;
}

.ecommerce button::after {
    content: "\f0374";
    display: block;
    font-family: "Material Design Icons";
    font-size: 16px;
    position: absolute;
    right: 20px;
    font-weight: 500;
    top: 30%;
    background-image: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
}
.authentication-bg {
    background-image: none!important;
    height: 100vh;
    background-size: cover;
    background-position: center;
}
.mat-mdc-table {
    background:none;
}
.mat-mdc-form-field-infix {
    flex: auto;
    min-width: 0;
    width: 179px;
    position: relative;
    box-sizing: border-box;
    height: 45px;
}
::ng-deep .mat-mdc-form-field-infix {
    min-height: 40px ;
    font-size: 14px;
    border-radius:8px
  
  }
  ::ng-deep .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 8px ;
    padding-bottom: 8px;
    
  }
  :host ::ng-deep {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 8px ;
        padding-bottom: 8px;
        
      }
      .mat-mdc-form-field-infix {
        min-height: 40px ;
        font-size: 14px;
        border-radius:8px
      
      }
    
   
    
  }
  // .metismenu .mm-collapse.mm-show{
  //   display: block!important;
  // }
  // .vertical-collpsed .vertical-menu #sidebar-menu > ul ul{
  //   position: fixed!important;
  // }

  .table > :not(caption) > * > * {
    border-bottom-color: #c3daee;
  }
  



